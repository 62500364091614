import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider, useDispatch, useSelector } from 'react-redux';

// Redux store
import store from '../../store/store';

// Helpers
import { getSelectedOptions, numberWithCommas, selectedOfferId } from './helpers';

const Summary = ({
  p11d,
  adminFee,
  isPersonal,
  VAT,
  derivativeId,
  financeLeaseTerm,
  financeLeaseMileage,
  financeLeaseInitialPayment,
  shortTerms,
}) => {
  const dispatch = useDispatch();
  const deal = useSelector((state) => state.Deal);
  const options = getSelectedOptions(deal);

  const offerId = selectedOfferId(deal);
  const {
    annualMileage,
    contractLength,
    initialMonths,
    maintenanceSelected,
    isFinanceLease,
    maintainedMonthlyPrice,
    monthlyPrice,
  } = deal;

  const [shortTerm] = useState(shortTerms.indexOf(contractLength) > -1);
  const [maintenancePrice, setMaintenancePrice] = useState(null);

  useEffect(() => {
    if (maintainedMonthlyPrice && monthlyPrice) {
      const price = Math.max(0, maintainedMonthlyPrice - monthlyPrice).toFixed(2);
      setMaintenancePrice(price);
    } else {
      setMaintenancePrice(0);
    }
  }, [maintainedMonthlyPrice, monthlyPrice]);

  const formatValues = (value) => {
    return `£${numberWithCommas(parseFloat(value).toFixed(2))}`;
  };

  return (
    <>
      <div className="l-deal__sidebar-summary drv-deal-sidebar-summary drv-mt-6">
        <hr className="drv-hr drv-mb-4 d-none d-xl-block" />
        <div className="drv-deal-sidebar-summary__title">Your Deal Summary</div>
        <ul className="drv-list-table">
          <li className="drv-list-table__item">
            <span className="drv-list-table__title">Lease Type</span>
            <span className="drv-list-table__data">
              {isFinanceLease ? 'Finance Lease' : `${isPersonal ? 'Personal' : 'Business'} Contract Hire`}
            </span>
          </li>
          <li className="drv-list-table__item">
            <span className="drv-list-table__title">P11D</span>
            <span className="drv-list-table__data">{formatValues(p11d)}</span>
          </li>
          <li className="drv-list-table__item">
            <span className="drv-list-table__title">Initial Payment</span>
            <span className="drv-list-table__data">
              {isFinanceLease ? financeLeaseInitialPayment : initialMonths} months
            </span>
          </li>
          <li className="drv-list-table__item">
            <span className="drv-list-table__title">Contract Length</span>
            <span className="drv-list-table__data">{isFinanceLease ? financeLeaseTerm : contractLength} months</span>
          </li>
          <li className="drv-list-table__item">
            <span className="drv-list-table__title">Annual mileage</span>
            <span className="drv-list-table__data">
              {isFinanceLease ? numberWithCommas(financeLeaseMileage) : numberWithCommas(annualMileage)} miles
            </span>
          </li>
          {maintenancePrice > 0 && (
            <li className="drv-list-table__item">
              <span className="drv-list-table__title">
                Maintenance Plan
                <a
                  href="/guides/personal-car-leasing/servicing-and-maintenance-packages"
                  target="_blank"
                  className="drv-list-table__info drv-link drv-text-accent"
                >
                  Find out more
                </a>
              </span>
              <span className="drv-list-table__data">
                {isFinanceLease || shortTerm ? (
                  'No Thanks'
                ) : (
                  <label
                    htmlFor="maintenanceCheck"
                    className="drv-checkbox__label drv-ml-1"
                    style={{ alignItems: 'flex-start' }}
                  >
                    <input
                      className="drv-checkbox"
                      style={{ marginRight: '0', marginTop: '4px' }}
                      checked={maintenanceSelected}
                      onChange={(event) =>
                        dispatch({
                          type: 'STORE_MAINTENANCE_SELECTED',
                          payload: event.target.checked,
                        })
                      }
                      type="checkbox"
                      id="maintenanceCheck"
                    />
                    <span className="drv-ml-2" style={{ textAlign: 'right' }}>
                      Only £{maintenancePrice}
                      <br />
                      {isPersonal ? 'inc. VAT' : 'ex. VAT'}
                    </span>
                  </label>
                )}
              </span>
            </li>
          )}
          <li className="drv-list-table__item">
            <span className="drv-list-table__title" data-controller="tooltip" data-tooltip-placement-value="left">
              <span className="drv-info" data-tooltip-target="button" />
              Arrangement fee
              <div className="drv-tooltip" role="tooltip" data-tooltip-target="tooltip">
                The arrangement fee is the cost of our services i.e. processing the order and lease agreement, and is
                charged at point of order. Please refer to our Initial Disclosure Document for more information.
                <div className="drv-tooltip__arrow" data-popper-arrow />
              </div>
            </span>
            <span className="drv-list-table__data">
              {isPersonal ? `${formatValues(adminFee * VAT)} inc VAT` : `${formatValues(adminFee)} ex VAT`}
            </span>
          </li>
          <li className="drv-list-table__item">
            <span className="drv-list-table__title" data-controller="tooltip" data-tooltip-placement-value="left">
              <span className="drv-info" data-tooltip-target="button" />
              Road tax
              <div className="drv-tooltip" role="tooltip" data-tooltip-target="tooltip">
                Additional charges may apply – please refer to our terms and conditions.
                <div className="drv-tooltip__arrow" data-popper-arrow />
              </div>
            </span>
            <span className="drv-list-table__data drv-text-bold">INCLUDED</span>
          </li>
          <li className="drv-list-table__item">
            <span className="drv-list-table__title" data-controller="tooltip" data-tooltip-placement-value="left">
              <span className="drv-info" data-tooltip-target="button" />
              Breakdown cover
              <div className="drv-tooltip" role="tooltip" data-tooltip-target="tooltip">
                Duration of cover may vary, please ask your Leasing Consultant for further details.
                <div className="drv-tooltip__arrow" data-popper-arrow />
              </div>
            </span>
            <span className="drv-list-table__data drv-text-bold">INCLUDED</span>
          </li>
          <li className="drv-list-table__item">
            <span className="drv-list-table__title" data-controller="tooltip" data-tooltip-placement-value="left">
              <span className="drv-info" data-tooltip-target="button" />
              Delivery
              <div className="drv-tooltip" role="tooltip" data-tooltip-target="tooltip">
                Vehicles usually driven unless stated, please speak with your leasing consultant for further
                information.
                <div className="drv-tooltip__arrow" data-popper-arrow />
              </div>
            </span>
            <span className="drv-list-table__data drv-text-bold">
              FREE UK MAINLAND <br />
              <small className="drv-text-small">(Exclusions apply)</small>
            </span>
          </li>
        </ul>
      </div>

      <div className="drv-deal-contents drv-deal-sidebar-order l-deal__sidebar-order">
        <h3 className="drv-deal-sidebar-summary__title drv-text-bold drv-text-accent drv-text-centre">
          Happy with your deal?
        </h3>
        <p className="drv-text drv-mb-4 drv-text-centre">
          Apply for finance and order your <br /> brand new car now!
        </p>
        <form noValidate action="/order-online" method="get">
          <input type="hidden" name="initial_payment_months" value={initialMonths} />
          <input type="hidden" name="maintenance_selected" value={maintenanceSelected} />

          {offerId && <input type="hidden" name="offer_id" value={offerId} />}

          {!offerId && <input type="hidden" name="term" value={contractLength} />}

          {!offerId && <input type="hidden" name="mileage" value={annualMileage} />}

          {!offerId && <input type="hidden" name="derivative_id" value={derivativeId} />}

          {options.length > 0 &&
            options.map((option) => (
              <input type="hidden" name="selected_option_ids[]" value={option.id} key={option.id} />
            ))}

          <button
            className="drv-button drv-deal-sidebar-summary__button drv-button--xl drv-button--gold drv-button--icon"
            style={{ justifyContent: 'center' }}
          >
            ORDER NOW
            <span className="drv-button__icon" />
          </button>
        </form>
        <p className="drv-text drv-mt-4 drv-mb-4 drv-text-centre">
          <span className="drv-text-bold">Need help?</span> Call us on{' '}
          <a href="tel:0118 3048 688" className="drv-link drv-text-larger drv-text-bold drv-link--unstyled">
            0118 920 5130
          </a>
        </p>
        <hr className="drv-hr" />
        <small className="drv-text drv-text-extra-small">
          Excess mileage fees may apply. Underwriting restrictions may apply. Please see our IDD for details of costs of
          our services, remuneration and commission.
        </small>
        <small className="drv-text drv-text-extra-small drv-text-bold drv-mt-2">
          Please note, Select Car Leasing is a broker, not a lender.
        </small>
      </div>
    </>
  );
};

Summary.propTypes = {
  p11d: PropTypes.string.isRequired,
  isPersonal: PropTypes.bool.isRequired,
  adminFee: PropTypes.number.isRequired,
  derivativeId: PropTypes.number.isRequired,
  financeLeaseTerm: PropTypes.number.isRequired,
  financeLeaseMileage: PropTypes.number.isRequired,
  financeLeaseInitialPayment: PropTypes.number.isRequired,
  VAT: PropTypes.number,
  shortTerms: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Summary.defaultProps = {
  VAT: 0,
};

export default function SummaryWrapper(props) {
  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Summary {...props} />
    </Provider>
  );
}
